.glyphicon-facebook,
.glyphicon-twitter,
.glyphicon-blog,
.glyphicon-instagram,
.glyphicon-linkedin,
.glyphicon-flickr,
.glyphicon-soundcloud,
.glyphicon-orcid,
.glyphicon-vimeo,
.glyphicon-youtube,
.glyphicon-rss,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right,
.glyphicon-calendar {
  background-color: var(--color-white);
  border: 1px solid var(--color-primary);
  border-radius: 50%;
  color: var(--color-primary);
  display: inline-block;
  height: 2.48em;
  line-height: 2.48em;
  text-align: center;
  width: 2.48em;
}

.glyphicon-twitter {
  background-color: var(--color-black);
  border: 1px solid var(--color-black);
  color: var(--color-white);
}

.glyphicon-facebook:hover,
.glyphicon-blog:hover,
.glyphicon-instagram:hover,
.glyphicon-linkedin:hover,
.glyphicon-flickr:hover,
.glyphicon-soundcloud:hover,
.glyphicon-orcid:hover,
.glyphicon-vimeo:hover,
.glyphicon-youtube:hover,
.glyphicon-rss:hover,
.carousel-control .glyphicon-chevron-left:hover,
.carousel-control .glyphicon-chevron-right:hover,
.glyphicon-calendar:hover {
  color: var(--color-white);
}

.glyphicon-twitter:hover {
  background-color: var(--color-gray-900);
}

.icon-sm {
  border-radius: 50%;
  display: inline-block;
  height: 1.875em;
  line-height: 1.875em;
  text-align: center;
  width: 1.875em;
}
