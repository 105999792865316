.input-group .btn {
  padding: calc(var(--padding-base-horizontal) * 1.5) !important;
}

.input-group input.btn {
  padding: calc(var(--padding-base-vertical) * 1.5) calc(var(--padding-base-horizontal) * 2) !important;
}

.form-control {
  border-left: 0;
  border-radius: 0;
  border-right: 0;
  border-top: 0;
  padding-left: 0;
}

.form-group {
  margin: 0.98em 0;
}
