.btn-primary,
.haiku-slideshow .btn-secondary {
  background-color: var(--color-white);
  border-color: var(--color-primary);
  border-radius: var(--base-border-radius) !important;
  color: var(--color-primary);
  position: static;
  text-shadow: none;
  transition: all 0.3s ease;
}

.btn-primary:hover,
.btn-primary:focus,
.haiku-slideshow .btn-secondary:hover,
.haiku-slideshow .btn-secondary:focus {
  background-color: var(--color-white);
  border-color: var(--anchor-font-color-hover);
  color: var(--color-primary);
}

.btn-primary::after,
.haiku-slideshow .btn-secondary::after {
  border: 0 !important;
  content: "";
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.btn-primary:hover,
.haiku-slideshow .btn-secondary:hover {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-white);
}

.btn-secondary,
.haiku-slideshow .btn-secondary,
.btn-default,
.link-button,
.btn-primary,
.btn-outline,
.btn-success,
.btn-info,
.btn-danger,
.btn-warning {
  padding: 0.32em 1.32em 0.3em !important;
}
