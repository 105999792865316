.rich-text h1 {
  font-size: calc(var(--h1-font-size) / 2);
}

.rich-text h2 {
  font-size: calc(var(--h2-font-size) / 2);
}

.rich-text h3 {
  font-size: var(--h3-font-size);
}

.rich-text h4 {
  font-size: var(--h4-font-size);
}

.rich-text h5 {
  font-size: var(--h5-font-size);
}

.rich-text h6 {
  font-size: var(--h6-font-size);
}

p,
li {
  color: var(--color-gray-800);
  font-size: 1rem;
  font-weight: 300;
  line-height: 2;
  margin: 0.64em 0;
}

.site-sidebar h1,
.site-sidebar h2,
.site-sidebar h3,
.site-sidebar h4,
.site-sidebar h5,
.site-sidebar h6,
.site-sidebar .heading-1,
.site-sidebar .heading-2,
.site-sidebar .heading-3,
.site-sidebar .heading-4,
.site-sidebar .heading-5,
.site-sidebar .heading-6 {
  color: var(--link-color);
}
