.haiku-slideshow .carousel-control-prev .glyphicon,
.haiku-slideshow .carousel-control-next .glyphicon,
.haiku-slideshow .carousel-indicators li {
  background-color: transparent;
  border: 1px solid var(--color-primary);
  border-radius: 50%;
  color: var(--color-primary);
}

.haiku-slideshow .carousel-control-prev .glyphicon:hover,
.haiku-slideshow .carousel-control-next .glyphicon:hover,
.haiku-slideshow .carousel-indicators li:hover {
  border-color: var(--color-primary-800);
  color: var(--color-primary-800);
}
