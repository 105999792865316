#site-top {
  background-color: var(--color-primary);
  margin-bottom: 0;
}

#site-header {
  background-color: var(--site-header-color);
  margin-bottom: 1.16em;
}

#site-header #server-status {
  margin-top: 0.64em;
}

#site-header header,
#site-header nav {
  padding-left: 0.64em;
}

#site-header nav {
  background: var(--color-primary);
  border-bottom-color: transparent;
  padding-right: 0.64em;
  padding-top: 0.98em;
}

#site-header .nav {
  margin-top: 0.98em;
}

#site-header .nav li a {
  color: var(--site-header-font-color);
  font-family: var(--subheader-font-family);
  letter-spacing: 1px;
  text-transform: lowercase;
}

#site-header .nav li a:hover,
#site-header .nav li a:focus {
  color: var(--site-header-font-color-hover);
}

#site-header .nav li.active a {
  color: var(--site-header-font-color-hover);
}

#site-header .navbar-collapse.in ul {
  background-color: var(--site-header-color);
}

#site-header .dropdown > .dropdown-menu {
  background-color: var(--site-header-color);
}

#site-actions ul {
  margin: 0;
}

#site-actions ul a {
  color: var(--color-white);
}

#site-search {
  margin-top: 0.98em;
}

#site-search .input-group {
  background-color: var(--color-white);
  border-radius: var(--base-border-radius);
  padding: 0 1.32em;
}

#site-search .input-group .btn {
  color: var(--color-primary);
  padding: 0 !important;
}

#site-search .input-group .btn:active,
#site-search .input-group .btn:hover,
#site-search .input-group .btn:focus {
  color: var(--theme-color-semi-trans);
}

#site-search .input-group .form-control {
  color: var(--color-white);
  text-align: left;
}

#site-search .input-group .form-control::-moz-placeholder {
  color: var(--color-white);
  opacity: 1;
}

#site-search .input-group .form-control:-ms-input-placeholder {
  color: var(--color-white);
}

#site-search .input-group .form-control::-webkit-input-placeholder {
  color: var(--color-white);
}

#site-search .input-group .form-control:focus {
  border-bottom-color: transparent;
}

#site-search .input-group .form-control:focus::-moz-placeholder {
  color: var(--color-white);
  opacity: 1;
}

#site-search .input-group .form-control:focus:-ms-input-placeholder {
  color: var(--color-white);
}

#site-search .input-group .form-control:focus::-webkit-input-placeholder {
  color: var(--color-white);
}

#icon-dropdowns li > a.active,
#icon-dropdowns li .to-reveal {
  background-color: var(--color-primary);
}

#icon-dropdowns #site-actions {
  display: block;
}

#site-parent__header {
  background-color: var(--feature-color);
  border-bottom-color: var(--color-primary);
}
