@charset "UTF-8";

@import './theme/albatross/buttons.css';
@import './theme/albatross/forms.css';
@import './theme/albatross/header.css';
@import './theme/albatross/icons.css';
@import './theme/albatross/images.css';
@import './theme/albatross/manager-themes.css';
@import './theme/albatross/portlets.css';
@import './theme/albatross/rich-text.css';
@import './theme/albatross/slideshow.css';
@import './theme/albatross/tinymce-editor.css';
@import './theme/albatross/typography.css';
