/*
  * Commented out because of HSD-761
body.mce-content-body h3:first-child,
.rich-text h3:first-child {
  border-bottom: 0;
  font-family: $header-font-family;
  font-size: $h2-font-size;
  text-transform: none;
}
*/

body.mce-content-body h4,
.rich-text h4 {
  text-transform: none;
}
